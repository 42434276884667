import classNames from 'classnames';
import React from 'react';
import useClickAway from '../hooks/useClickAway';

type Props = {
    downloadPdfWithPagination: boolean;
    setDownloadPdfWithPagination: Function;
    dropdownClasses?: string;
    setDownloadOptions: Function;
};

const DownloadOptionsDropdown = ({
    downloadPdfWithPagination,
    setDownloadPdfWithPagination,
    dropdownClasses,
    setDownloadOptions,
}: Props) => {
    const toggleVisibility = (
        visibility: boolean,
        e: React.MouseEvent<HTMLButtonElement>,
    ) => {
        setDownloadOptions(visibility);
        // stop the click outside from reopening the menu
        e.stopPropagation();
    };

    const { ref } = useClickAway(toggleVisibility);

    return (
        <div
            className={classNames(
                'shadow px-0 py-8 download-options me-lg-24',
                dropdownClasses,
            )}
            ref={ref}
        >
            <div className="body-2 text-black-700 py-8 px-12 text-start">
                Download options:
            </div>
            <button
                className={classNames(
                    'btn row d-flex rounded-0 w-100 ms-0 p-8',
                    {
                        'download-options__selected': !downloadPdfWithPagination,
                    },
                )}
                onClick={() => {
                    setDownloadPdfWithPagination(false);
                    setDownloadOptions(false);
                }}
            >
                <i className="col-1 material-icons fs-3 ps-0 text-tractiq">
                    download
                </i>
                <div className="col-8 ps-8 text-start">PDF (no pagination)</div>
                {!downloadPdfWithPagination && (
                    <i className="col-1 ms-auto material-icons me-12 text-tractiq">
                        check
                    </i>
                )}
            </button>
            <button
                className={classNames(
                    'btn row d-flex rounded-0 w-100 ms-0 p-8',
                    {
                        'download-options__selected': downloadPdfWithPagination,
                    },
                )}
                onClick={() => {
                    setDownloadPdfWithPagination(true);
                    setDownloadOptions(false);
                }}
            >
                <i className="col-1 material-icons fs-3 ps-0 text-tractiq">
                    download
                </i>
                <div className="col-8 ps-8 text-start">PDF</div>
                {downloadPdfWithPagination && (
                    <i className="col-1 ms-auto material-icons me-12 text-tractiq">
                        check
                    </i>
                )}
            </button>
        </div>
    );
};

export default DownloadOptionsDropdown;
